









































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default defineComponent({
  name: 'uOttawa1321Lab4Question4',
  components: {
    FileUploadUiComponent,
    StembleLatex,
    TableInput,
    STextarea,
    CalculationInput,
    VTextField,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        aiQuestionResponseB: null,
        aiQuestionResponseC: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      attachments: [],
      questions: [
        {
          en: 'The reagent shown below is diisobutylaluminum hydride, or DIBAL. Like sodium borohydride, it is a source of nucleophilic hydride.',
          fr: "Le réactif présenté ci-dessous est le diisobutylaluminium hydride, ou DIBAL. Comme le borohydrure de sodium, il est une source d'hydrure nucléophile.",
        },
        {
          en: 'a) Draw and label the configurations of each stereocentre in the product of the above reaction (with appropriate work-up) and upload the image using the field below.',
          fr: "a) Tracez et étiquetez les configurations de chaque stéréocentre dans le produit de la réaction ci-dessus (avec le travail approprié) et téléchargez l'image en utilisant le champ ci-dessous.",
        },
        {
          en: 'b) Provide a justification for the products and designations you drew in part a).',
          fr: 'b) Fournissez une justification pour les produits et les désignations que vous avez dessinés dans la partie a).',
        },
        {
          en: 'c) Unlike $\\ce{NaBH4},$ reductions performed with DIBAL require equimolar amounts of DIBAL and substrate. Explain this difference.',
          fr: 'c) Contrairement à $\\ce{NaBH4},$ les réductions effectuées avec le DIBAL nécessitent des quantités équimolaires de DIBAL et de substrat. Expliquez cette différence.',
        },
      ],
      productImage: [] as File[],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
  computed: {
    imageName() {
      return '/img/assignments/reduction_DIBAL.png';
    },
    fileUploadLabel(): string {
      return this.inputs.language === 'en' ? 'Upload your file' : 'Téléchargez vos fichiers';
    },
  },
});
